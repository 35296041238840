import { Box, Grid, useTheme } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { EmbeddedPowerBIParams, TelemetryFunc } from "../entities";
import EmbeddedPowerBI from "./EmbeddedPowerBI";
import PowerBiReportMenu from "./PowerBiReportMenu";
import { PowerBiReportStyles } from "../styles";
import { usePowerBiReportCenter } from "../hooks";
import { useObserveElementSize } from "../../../utils/useObserveElementSize";
import { PbiContext } from "./PbiContext";
import PageHeader from "../../../global-layout/PageHeader";
import PowerBiDateRanges from "./report-app-bar/PowerBiDateRanges";
import ViewBookmarks from "./report-app-bar/ViewBookmarks";
import { ActiveReportStore, EmbeddedPowerBiStore } from "../stores";
import { FeatureDefinitions } from "../../../../src/utils/FeatureDefinitions";
import IdcsChangeRequestButton from "./report-app-bar/IdcsChangeRequestButton";
import { Contact } from "../../../../../customer-experience/src/lib/ShiOneClient";

export interface PbiTelemetryProps {
  trackLoaded?: TelemetryFunc;
  trackPageChanged?: TelemetryFunc;
  trackRendered?: TelemetryFunc;
  trackDataSelected?: TelemetryFunc;
  trackButtonClicked?: TelemetryFunc;
  trackFiltersApplied?: TelemetryFunc;
  trackCommandTriggered?: TelemetryFunc;
  trackBookmarkApplied?: TelemetryFunc;
  trackDataHyperlinkClicked?: TelemetryFunc;
  trackVisualRendered?: TelemetryFunc;
  trackVisualClicked?: TelemetryFunc;
  trackSelectionChanged?: TelemetryFunc;
  trackRenderingStarted?: TelemetryFunc;
  trackError?: TelemetryFunc;
}

export interface PbiBookmarksProps {
  useCurrentUser: unknown;
  useVirtualAdmin: unknown;
  getBookmarks: unknown;
  postBookmarks: unknown;
  putBookmarks: unknown;
  deleteBookmarks: unknown;
}

export interface PbiWebWorkerProps {
  webWorkerGetNewAccessToken: unknown;
  telemetryFunc?: unknown;
}

export interface PowerBiReportCenterProps {
  header: string | string[];
  title?: string;
  reports: EmbeddedPowerBIParams[];
  showTimeRange?: boolean;
  showHeader?: boolean;
  expandFilterPane?: boolean;
  children?: React.ReactNode;
  usePbiHook: any;
  webWorkerProps: PbiWebWorkerProps;
  pbiTelemetryProps: PbiTelemetryProps;
  pbiBookmarksProps: PbiBookmarksProps;
  reportRelatedFeature?: (typeof FeatureDefinitions)[keyof typeof FeatureDefinitions];
}

const getReportContainerHeight = (width: number) => {
  const windowHeight = window.innerHeight;
  if (width) {
    if (windowHeight > width * (8.8 / 16) + 200) {
      return `${width * (8.8 / 16)}px`;
    }
    return windowHeight - 200;
  }
  return "auto";
};

export function PowerBiReportCenter({
  header = "",
  reports = [],
  showTimeRange = true,
  showHeader = true,
  children = <></>,
  usePbiHook,
  webWorkerProps,
  pbiTelemetryProps,
  pbiBookmarksProps,
  reportRelatedFeature,
}: PowerBiReportCenterProps) {
  const theme = useTheme();
  const styles = PowerBiReportStyles(theme);
  const { MoveReportDown } = usePowerBiReportCenter({
    reports,
    showTimeRange,
    showHeader,
  });
  const [showIntervals] = EmbeddedPowerBiStore((s) => [s.showIntervals]);
  const ActiveReportParams = ActiveReportStore((s) => s.activeReportParams);
  const setActiveReportRelatedFeature = ActiveReportStore(
    (s) => s.setActiveReportRelatedFeature,
  );

  const { measuredRef, width } = useObserveElementSize();
  const reportContainerHeight = getReportContainerHeight(width);

  useEffect(() => {
    if (!!reportRelatedFeature) {
      setActiveReportRelatedFeature(reportRelatedFeature);
    }
  }, [reportRelatedFeature]);

  const memoizedValue = useMemo(() => {
    return { usePbiHook, webWorkerProps, pbiTelemetryProps, pbiBookmarksProps };
  }, [usePbiHook, webWorkerProps, pbiTelemetryProps, pbiBookmarksProps]);

  const hideBookmarks = ActiveReportParams?.hideBookmarks ?? false;

  // @ts-ignore
  const accountId = (pbiBookmarksProps.useCurrentUser() as Contact)?.accountID;
  const hasIdcsAccess = accountId === 31976; //only this customer has access to IDCS

  return (
    <PbiContext.Provider value={memoizedValue}>
      <div style={styles.root}>
        <Box sx={{ paddingBottom: "1rem" }}>
          <PageHeader
            header={header}
            headerComponents={
              <Box sx={{ display: "flex", gap: 1.5, alignItems: "center" }}>
                <Box
                  sx={{ display: { xs: "none", sm: "block" }, fontSize: "xs" }}
                >
                  {children}
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    gap: 1.5,
                    fontSize: "xs",
                  }}
                >
                  {showIntervals && !ActiveReportParams?.hideIntervals && (
                    <PowerBiDateRanges />
                  )}
                  {hasIdcsAccess && <IdcsChangeRequestButton />}
                  {!hideBookmarks && <ViewBookmarks />}
                </Box>
              </Box>
            }
          />
        </Box>
        <Grid
          container
          justifyContent="flex-end"
          flexWrap={"nowrap"}
          sx={[
            styles.whiteBackground,
            MoveReportDown && { display: "none" },
            {
              height: reportContainerHeight,
              aspectRatio: 16 / 8.8,
            },
          ]}
        >
          <PowerBiReportMenu reports={reports} />
          <Grid item xs>
            <EmbeddedPowerBI ref={measuredRef} />
          </Grid>
        </Grid>
      </div>
    </PbiContext.Provider>
  );
}
