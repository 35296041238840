import React from "react";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getMaturityChartCategoryIcon } from "@features/assessments-feature/helpers/assessmentMaturityChartHelpers";
import MaturityChartSubcategory from "@features/assessments-feature/components/maturity-chart/MaturityChartSubcategory";
import useGetAssessmentMaturityChart from "@features/assessments-feature/hooks/useGetAssessmentMaturityChart";
import { useParams } from "react-router-dom";
import assessmentViewsMap from "@features/assessments-feature/utils/assessmentViewsMap";
import ErrorIcon from "@mui/icons-material/Error";
import CircularProgress from "@mui/material/CircularProgress";
import {
  PrintOrientation,
  useAssessmentExportContext,
} from "@features/assessments-feature/contexts-providers/AssessmentExportContextProvider";

const MaturityChartCardExport = () => {
  const { program } = useParams();
  const { printLayout } = useAssessmentExportContext();

  const assessmentProgram = assessmentViewsMap.get(program ?? "")?.id;
  const {
    data: assessmentData,
    isLoading,
    isError,
  } = useGetAssessmentMaturityChart(assessmentProgram);
  if (isLoading) {
    return <CircularProgress size={20} />;
  }
  if (isError) {
    return (
      <>
        <ErrorIcon color={"error"} />
        <Typography component="span" color={"error"} ml={2}>
          Error loading maturity chart's cards
        </Typography>
      </>
    );
  }
  const isLandscape = printLayout === PrintOrientation.Landscape;
  return (
    <Box className={"assessment-break"} width={"100%"} sx={{ zoom: "50%" }}>
      <Grid container spacing={1}>
        {assessmentData?.responseCategories?.map((category) => {
          const totalCategoryCapabilities =
            category?.responseSubcategories?.reduce(
              (acc, cur) => acc + (cur?.responseCapabilities?.length ?? 0),
              0,
            );
          return (
            <Grid
              key={category.name}
              item
              pr={1}
              xs={isLandscape ? "auto" : false}
            >
              <Accordion
                defaultExpanded
                sx={{ border: "0.5px gray solid", boxShadow: "unset" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    "&.Mui-expanded": { minHeight: 3 },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 1.5,
                    },
                  }}
                >
                  {getMaturityChartCategoryIcon(category.name ?? "")}
                  <Typography variant={"subtitle1"}>{category.name}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0 8px 16px" }}>
                  <Grid
                    container
                    gap={2}
                    columns={totalCategoryCapabilities ?? 16}
                    width={"100%"}
                    height={"100%"}
                  >
                    {category?.responseSubcategories?.map(
                      (subcategory, index) => {
                        const key = (subcategory.name ?? "") + index;
                        const isLastIndex =
                          index ===
                          (category.responseSubcategories?.length ?? 1) - 1;
                        return (
                          <Grid key={key} item xs={isLastIndex ? "auto" : true}>
                            <MaturityChartSubcategory
                              subcategory={subcategory}
                              assessmentProgram={assessmentProgram}
                            />
                          </Grid>
                        );
                      },
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MaturityChartCardExport;
